/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/comma-dangle */
/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable @typescript-eslint/indent */

import React, { FocusEventHandler, useMemo } from 'react';
import {
  ImageList,
  ImageListItem,
  CardActionArea,
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import Typography from '@mui/material/Typography';
import { nanoid } from '@reduxjs/toolkit';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorResult, RGBColor } from 'react-color';
import useItem from '../../../hook/useItem';
import { StageData } from '../../../redux/currentStageData';
import fontList from '../../../config/fonts.json';
import TabPanel from '../../tab/TabPanel';
import {
  StyledTab,
  StyledTabs,
  StyledToggleButtonGroup,
  setTabId,
} from './StyledPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import backgroundList from '../../../config/backgrounds.json';
import { PanelItemProps } from './FramePanel';
import RWColorPicker from '../../colorPicker/RWColorPicker';
import '../styles/EachPanel.scss';
import PanelItemNew from './PanelItemNew';

export default function BackgroundPanel({ onStageChange }: PanelItemProps) {
  const colorToRGB = (
    rgb: string,
  ): { red: number; green: number; blue: number; alpha: number } => {
    const result =
      rgb.length > 0 ? rgb.replace(/[^\d,.]/g, '').split(',') : null;

    return result
      ? {
          red: parseInt(result[0], 10),
          green: parseInt(result[1], 10),
          blue: parseInt(result[2], 10),
          alpha: parseFloat(result[3] ?? '1.0'),
        }
      : {
          red: 0,
          green: 0,
          blue: 0,
          alpha: 0,
        };
  };

  const stringToHSL = (
    hsl: string,
  ): { hue: number; saturation: number; luminance: number } => {
    const result =
      hsl.length > 0 ? hsl.replace(/[^\d,.]/g, '').split(',') : null;

    if (result) {
      const hue = parseInt(result[0], 10);
      const saturation = parseInt(result[1], 10) / 100.0;
      const luminance = parseInt(result[2], 10) / 100.0;

      return {
        hue,
        saturation,
        luminance,
      };
    }

    return {
      hue: 0,
      saturation: 0,
      luminance: 0,
    };
  };

  const { createItem, removeItem, updateItem, stageData } = useItem();
  const targetItemBorder = useMemo(
    () =>
      stageData.find(
        (data) =>
          data.id === 'background-image' ||
          data.attrs.id === 'background-image',
      ),
    [stageData],
  );

  const [imageColor, setImageColor] = React.useState<RGBColor>(() =>
    targetItemBorder?.attrs?.color
      ? targetItemBorder.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );
  const [imageColors, setImageColors] = React.useState<ColorResult>();

  const handleColorChange = (color: ColorResult) => {
    setImageColor(color.rgb);
    setImageColors(color);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorder) {
      const { h, s, l, a } = color.hsl;
      onStageChange('Updated Background Color');
      updateItem(targetItemBorder.id, (prevData) => ({
        ...targetItemBorder.attrs,
        hue: h,
        saturation: s,
        luminance: l,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setImageColor(color.rgb);
      setImageColors(color);
    }
  };

  const insertImage = (data: { [key: string]: any }) => {
    const imageSrc = new Image();
    const imageSource = `${process.env.PUBLIC_URL}/images/backgrounds/${data.imageSrc}`;

    const { h, s, l, a } = imageColors?.hsl ?? {
      h: 0.0,
      s: 0.0,
      l: 0.0,
      a: 1.0,
    };

    removeItem('background-image');
    onStageChange('Updated Background Image');
    imageSrc.onload = () => {
      const newBackground: StageData = {
        id: 'background-image',
        attrs: {
          name: 'label-target',
          dataItemType: 'image',
          x: (canvasWidth - data.imageWidth) / 2,
          y: (canvasHeight - data.imageHeight) / 2,
          width: data.imageWidth,
          height: data.imageHeight,
          hue: h,
          saturation: s,
          luminance: l,
          opacity: a,
          src: imageSource,
          zIndex: 1,
          draggable: false,
          brightness: 0,
          color: { imageColor },
          filters: ['HSL'],
          updatedAt: Date.now(),
        },
      };
      createItem(newBackground);
    };

    imageSrc.src = imageSource;
  };

  const targetItemBorderBackground = useMemo(
    () =>
      stageData.find(
        (data) =>
          data.id === 'border-background' ||
          data.attrs.id === 'border-background',
      ),
    [stageData],
  );

  const [backgroundColor, setBackgroundColor] = React.useState<RGBColor>(() =>
    targetItemBorderBackground?.attrs?.color
      ? targetItemBorderBackground.attrs.color
      : {
          r: 0,
          g: 0,
          b: 0,
          a: 1.0,
        },
  );
  const [backgroundColors, setBackgroundColors] = React.useState<ColorResult>();

  const handleBackgroundColorChange = (color: ColorResult) => {
    setBackgroundColor(color.rgb);
    setBackgroundColors(color);
    document.body.style.backgroundColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
  };

  const handleBackgroundColorChangeCompleted = (color: ColorResult) => {
    if (targetItemBorderBackground) {
      const { r, b, g, a } = color.rgb;
      onStageChange('Updated Background Color');
      updateItem(targetItemBorderBackground.id, (prevData) => ({
        ...targetItemBorderBackground.attrs,
        red: r,
        green: g,
        blue: b,
        opacity: a,
        color: color.rgb,
        updatedAt: Date.now(),
      }));

      setBackgroundColor(color.rgb);
      setBackgroundColors(color);
    }
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange} sx={{ mt: 1 }}>
        <StyledTab label="Background" {...setTabId(0)} />
        <StyledTab label="Styles" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Grid container>
          <Grid item xs={12} display="flex" flexWrap="wrap">
            <ImageList cols={2} sx={{ my: 0, maxHeight: 480 }}>
              <PanelItemNew
                onClick={() => {
                  removeItem('background-image');
                }}
              >
                <ImageListItem>
                  <Typography textAlign="center">None</Typography>
                </ImageListItem>
              </PanelItemNew>
              {backgroundList.map((item, index) => (
                <PanelItemNew
                  key={index}
                  onClick={() => {
                    insertImage(item);
                  }}
                >
                  <ImageListItem key={index} sx={{ margin: 2 }}>
                    <img
                      key={index}
                      src={`${process.env.PUBLIC_URL}/images/backgrounds/${item.imageSrc}`}
                      srcSet={`${process.env.PUBLIC_URL}/images/backgrounds/${item.imageSrc}`}
                      alt={item.id}
                      loading="lazy"
                    />
                    <span className="background" />
                  </ImageListItem>
                </PanelItemNew>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <Grid container mt={2}>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <RWColorPicker
                color={imageColor}
                onChange={handleColorChange}
                onChangeComplete={handleColorChangeCompleted}
              />
            </Grid>
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <RWColorPicker
                options={{
                  fillLabel: 'Background',
                  showHue: false,
                  showAlpha: false,
                  showSaturation: false,
                  fillClickable: true,
                }}
                color={backgroundColor}
                onChange={handleBackgroundColorChange}
                onChangeComplete={handleBackgroundColorChangeCompleted}
              />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    </>
  );
}
