/* eslint-disable object-curly-newline */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { FocusEventHandler, useEffect, useMemo } from 'react';
import {
  ImageList,
  ImageListItem,
  CardActionArea,
  Box,
  Grid,
  TextField,
  Select,
  MenuItem,
  Autocomplete,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Divider,
  Tab,
  Tabs,
  Checkbox,
} from '@mui/material';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import Typography from '@mui/material/Typography';
import { nanoid } from '@reduxjs/toolkit';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColorResult, RGBColor } from 'react-color';
import WebFont from 'webfontloader';
import useItem from '../../../hook/useItem';
import { StageData } from '../../../redux/currentStageData';
import fontList from '../../../config/fonts.json';
import TabPanel from '../../tab/TabPanel';
import {
  StyledTab,
  StyledTabs,
  StyledToggleButtonGroup,
  setTabId,
} from './StyledPanel';
import { canvasHeight, canvasWidth } from '../../CanvasConstants';
import { PanelItemProps } from './FramePanel';
import RWColorPicker from '../../colorPicker/RWColorPicker';
import '../styles/TextPanel.scss';

export default function TextPanel({ onStageChange }: PanelItemProps) {
  const [alignment, setAlignment] = React.useState('left');
  const [formats, setFormats] = React.useState(() => ['normal']);

  const { createItem, removeItem, updateItem, stageData } = useItem();
  const targetItemText = useMemo(
    () =>
      stageData.find((data) => data.id === 'text' || data.attrs.id === 'text'),
    [stageData],
  );

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    if (targetItemText) {
      onStageChange('Updated Text Format');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontStyle: 'italic',
        updatedAt: Date.now(),
      }));
    }

    setFormats(newFormats);
  };

  useEffect(() => {
    WebFont.load({
      custom: {
        families: fontList.map((font) => font.fontFamily),
      },
      active: () => {
        console.log('Fonts loaded successfully!');
      },
      inactive: () => {
        console.log('Fonts failed to load.');
      },
    });
  }, []);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    if (targetItemText) {
      onStageChange('Updated Text Alignment');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        align: newAlignment,
        updatedAt: Date.now(),
      }));
    }

    setAlignment(newAlignment);
  };

  const [color, setColor] = React.useState<RGBColor>(() =>
    targetItemText?.attrs?.color
      ? targetItemText.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );

  const handleColorChange = (color: any) => {
    setColor(color.rgb);
  };

  const handleColorChangeCompleted = (color: ColorResult) => {
    if (targetItemText) {
      onStageChange('Updated Text Color');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fill: color.hex,
        updatedAt: Date.now(),
      }));
      setColor(color.rgb);
    }
  };

  // OUTLINES ============================================

  const [showOutline, setShowOutline] = React.useState(false);
  const [outlineColors, setOutlineColors] = React.useState<ColorResult>();
  const [outlineColor, setOutlineColor] = React.useState<RGBColor>(() =>
    targetItemText?.attrs?.color
      ? targetItemText.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );
  const [outlineWidth, setOutlineWidth] = React.useState<number>(1);

  const handleOutlineColorChange = (color: any) => {
    setOutlineColor(color.rgb);
    setOutlineColors(color);
  };

  const handleShowOutlineChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (targetItemText) {
      setShowOutline(event.target.checked);

      if (event.target.checked) {
        onStageChange('Updated Text Outline');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          stroke: outlineColors?.hex ?? '#fff',
          strokeWidth: outlineWidth,
          updatedAt: Date.now(),
        }));
      } else {
        onStageChange('Updated Text Outline');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          stroke: null,
          strokeWidth: null,
          updatedAt: Date.now(),
        }));
      }
    }
  };

  const handleOutlineColorChangeCompleted = (color: ColorResult) => {
    if (targetItemText) {
      onStageChange('Updated Text Outline Color');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        stroke: color.hex,
        updatedAt: Date.now(),
      }));
    }

    setOutlineColor(color.rgb);
  };

  const onOutlineWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text Outline Size');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        strokeWidth: parseInt(event.target.value, 10),
        updatedAt: Date.now(),
      }));
    }

    setOutlineWidth(parseInt(event.target.value, 10));
  };

  // SHADOWS ============================================
  const [showShadow, setShowShadow] = React.useState(false);
  const [shadowColors, setShadowColors] = React.useState<ColorResult>();
  const [shadowColor, setShadowColor] = React.useState<RGBColor>(() =>
    targetItemText?.attrs?.color
      ? targetItemText.attrs.color
      : {
          r: 255,
          g: 255,
          b: 255,
          a: 1.0,
        },
  );
  const [shadowWidth, setShadowWidth] = React.useState<number>(1);

  const handleShadowColorChange = (color: any) => {
    setShadowColor(color.rgb);
    setShadowColors(color);
  };

  const handleShowShadowChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (targetItemText) {
      setShowShadow(event.target.checked);

      if (event.target.checked) {
        onStageChange('Updated Text Shadow');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          shadowColor: shadowColors?.hex ?? '#fff',
          shadowOffsetX: shadowWidth,
          shadowOffsetY: shadowWidth,
          updatedAt: Date.now(),
        }));
      } else {
        onStageChange('Updated Text Shadow');
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          shadowColor: null,
          shadowOffsetX: null,
          shadowOffsetY: null,
          updatedAt: Date.now(),
        }));
      }
    }
  };

  const handleShadowColorChangeCompleted = (color: ColorResult) => {
    if (targetItemText) {
      onStageChange('Updated Text Shadow Color');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        shadowColor: color.hex,
        updatedAt: Date.now(),
      }));
    }

    setShadowColor(color.rgb);
  };

  const onShadowWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text Shadow Size');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        shadowOffsetX: parseInt(event.target.value, 10),
        shadowOffsetY: parseInt(event.target.value, 10),
        updatedAt: Date.now(),
      }));
    }

    setShadowWidth(parseInt(event.target.value, 10));
  };

  const [font, setFont] = React.useState<string>(() =>
    targetItemText && targetItemText.attrs.text.trim().length > 0
      ? targetItemText.attrs.text
      : fontList[0].fontFamily,
  );
  const [text, setText] = React.useState<string>(() =>
    targetItemText && targetItemText.attrs.text.trim().length > 0
      ? targetItemText.attrs.text
      : '',
  );
  const [fontSize, setFontSize] = React.useState<number>(() =>
    targetItemText && targetItemText.attrs.fontSize
      ? targetItemText.attrs.fontSize / 3
      : 20,
  );
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(0);
  };

  const colorToRGB = (
    rgb: string,
  ): { red: number; green: number; blue: number } => {
    const result = rgb.replace(/[^\d,.]/g, '').split(',');
    return result
      ? {
          red: parseInt(result[0], 10),
          green: parseInt(result[1], 10),
          blue: parseInt(result[2], 10),
        }
      : {
          red: 0,
          green: 0,
          blue: 0,
        };
  };

  const onFontChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: {
      type: string;
      id: string;
      width: number;
      height: number;
      fontSize: number;
      fontFamily: string;
    },
  ) => {
    if (targetItemText) {
      onStageChange('Updated Text Font');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontFamily: value.fontFamily,
        updatedAt: Date.now(),
      }));
    }

    setFont(value.fontFamily);
  };

  const insertText = (data: { [key: string]: any }) => {
    const { width } = data;
    const { height } = data;
    const newText: StageData = {
      id: 'text',
      attrs: {
        name: 'label-target',
        dataItemType: 'text',
        fontStyle: data.fontStyle,
        fill: data.fill,
        x: (canvasWidth - width) / 2,
        y: (canvasHeight - height) / 2,
        fontSize: data.fontSize,
        fontFamily: data.fontFamily,
        text: data.text,
        align: data.textAlign,
        verticalAlign: 'top',
        zIndex: 8,
        draggable: true,
        brightness: 0,
        updatedAt: Date.now(),
      },
    };
    createItem(newText);
  };

  const onTextBlur = () => {
    if (targetItemText) {
      onStageChange('Updated Text');
      if (!text || text.trim().length === 0) {
        // string is empty
        removeItem(targetItemText.id);
      } else {
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          text,
          updatedAt: Date.now(),
        }));
      }
    } else if (!(!text || text.trim().length === 0)) {
      // string is not empty
      onStageChange('Updated Text');
      insertText({
        textAlign: alignment,
        fontFamily: font,
        text,
        fill: color,
        width: 250,
        height: 35,
        fontStyle: formats.join(' '),
        fontSize: fontSize * 3,
      });
    }
  };

  const onFontSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text Font Size');
      updateItem(targetItemText.id, (prevData) => ({
        ...targetItemText.attrs,
        fontSize: parseInt(event.target.value, 10) * 3,
        updatedAt: Date.now(),
      }));
    }

    setFontSize(parseInt(event.target.value, 10));
  };

  const onTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (targetItemText) {
      onStageChange('Updated Text');
      if (!event.target.value || event.target.value.trim().length === 0) {
        // string is empty
        removeItem(targetItemText.id);
      } else {
        updateItem(targetItemText.id, (prevData) => ({
          ...targetItemText.attrs,
          text: event.target.value,
          updatedAt: Date.now(),
        }));
      }
    } else if (
      !(!event.target.value || event.target.value.trim().length === 0)
    ) {
      // string is not empty
      onStageChange('Updated Text');
      insertText({
        textAlign: alignment,
        fontFamily: font,
        text: event.target.value,
        fill: color,
        width: 250,
        height: 35,
        fontStyle: formats.join(' '),
        fontSize: fontSize * 3,
      });
    }

    setText(event.target.value);
  };

  return (
    <>
      <StyledTabs value={tabValue} onChange={handleTabChange} sx={{ mt: 1 }}>
        <StyledTab label="Text" {...setTabId(0)} />
        <StyledTab label="" {...setTabId(0)} />
      </StyledTabs>
      <TabPanel value={tabValue} index={0}>
        <Box>
          <Grid container mt={2}>
            <Grid item xs={12} mb={2}>
              <TextField
                id="multiline-text"
                multiline
                defaultValue={text}
                placeholder="Write here"
                fullWidth
                onChange={onTextChange}
                onBlur={onTextBlur}
                minRows={5}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <FormControlLabel
                value="Font"
                label="Font"
                sx={{
                  ml: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
                control={
                  <Box
                    sx={{
                      marginRight: 'auto',
                      display: 'flex',
                      justifyContent: 'flex-center',
                      width: '100%',
                    }}
                  >
                    <Autocomplete
                      id="font-select-autocomplete"
                      blurOnSelect
                      disableClearable
                      sx={{ width: '100%' }}
                      onChange={onFontChange}
                      options={fontList}
                      size="small"
                      defaultValue={fontList[0]}
                      getOptionLabel={(option) => option.fontFamily}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <StyledToggleButtonGroup
                      size="small"
                      value={formats}
                      onChange={handleFormat}
                      aria-label="text formatting"
                    >
                      {/* <ToggleButton value="bold" aria-label="bold">
                        <FontAwesomeIcon color="white" icon={solid('bold')} />
                      </ToggleButton> */}
                    </StyledToggleButtonGroup>
                  </Box>
                }
                labelPlacement="start"
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <Grid container>
                <Grid item xs={6}>
                  <Box>
                    <RWColorPicker
                      options={{
                        showHue: false,
                        showAlpha: false,
                        showSaturation: false,
                        fillClickable: true,
                      }}
                      color={color}
                      onChange={handleColorChange}
                      onChangeComplete={handleColorChangeCompleted}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Size"
                    label="Size"
                    sx={{
                      ml: 2,
                      width: '100%',
                      height: 30,
                    }}
                    control={
                      <Box
                        sx={{
                          width: 60,
                          marginLeft: 'auto',
                          mr: 2,
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <TextField
                          id="outlined-font-size"
                          type="number"
                          fullWidth
                          size="small"
                          defaultValue={fontSize}
                          onChange={onFontSizeChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} mb={2} height={30}>
              <FormControlLabel
                value="Outline"
                label="Outline"
                sx={{ ml: 0, width: '100%' }}
                control={
                  <Checkbox
                    checked={showOutline}
                    onChange={handleShowOutlineChange}
                    size="small"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
            {showOutline && (
              <Grid
                item
                xs={12}
                mb={2}
                display="flex"
                flexWrap="wrap"
                height={30}
              >
                <Grid container ml={3}>
                  <Grid item xs={6}>
                    <Box>
                      <RWColorPicker
                        options={{
                          showHue: false,
                          showAlpha: false,
                          showSaturation: false,
                          fillClickable: true,
                          fillLabel: 'Color',
                        }}
                        color={outlineColor}
                        onChange={handleOutlineColorChange}
                        onChangeComplete={handleOutlineColorChangeCompleted}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="Size"
                      label="Size"
                      sx={{ ml: 0, width: '100%', height: 30 }}
                      control={
                        <Box
                          sx={{
                            width: 70,
                            marginLeft: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <TextField
                            id="outlined-font-size"
                            type="number"
                            fullWidth
                            size="small"
                            defaultValue={outlineWidth}
                            onChange={onOutlineWidthChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      }
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} mb={2} height={30}>
              <FormControlLabel
                value="Shadow"
                label="Shadow"
                sx={{ ml: 0, width: '100%' }}
                control={
                  <Checkbox
                    checked={showShadow}
                    onChange={handleShowShadowChange}
                    size="small"
                  />
                }
                labelPlacement="end"
              />
            </Grid>
            {showShadow && (
              <Grid
                item
                xs={12}
                mb={2}
                display="flex"
                flexWrap="wrap"
                height={30}
              >
                <Grid container ml={3}>
                  <Grid item xs={6}>
                    <Box>
                      <RWColorPicker
                        options={{
                          showHue: false,
                          showAlpha: false,
                          showSaturation: false,
                          fillClickable: true,
                          fillLabel: 'Color',
                        }}
                        color={shadowColor}
                        onChange={handleShadowColorChange}
                        onChangeComplete={handleShadowColorChangeCompleted}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      value="Size"
                      label="Size"
                      sx={{ ml: 0, width: '100%', height: 30 }}
                      control={
                        <Box
                          sx={{
                            width: 70,
                            marginLeft: 'auto',
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <TextField
                            id="Shadowed-font-size"
                            type="number"
                            fullWidth
                            size="small"
                            defaultValue={shadowWidth}
                            onChange={onShadowWidthChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Box>
                      }
                      labelPlacement="start"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} mb={2} display="flex" flexWrap="wrap">
              <FormControlLabel
                value="Alignment"
                label="Alignment"
                sx={{
                  ml: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
                control={
                  <Box
                    sx={{
                      marginRight: 'auto',
                      display: 'flex',
                      // justifyContent: 'flex-end',
                    }}
                  >
                    <StyledToggleButtonGroup
                      size="small"
                      value={alignment}
                      exclusive
                      onChange={handleAlignment}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="left" aria-label="left aligned">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-left')}
                        />
                      </ToggleButton>
                      <ToggleButton value="center" aria-label="centered">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-center')}
                        />
                      </ToggleButton>
                      <ToggleButton value="right" aria-label="right aligned">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-right')}
                        />
                      </ToggleButton>
                      <ToggleButton value="justify" aria-label="justified">
                        <FontAwesomeIcon
                          color="white"
                          icon={solid('align-justify')}
                        />
                      </ToggleButton>
                    </StyledToggleButtonGroup>
                  </Box>
                }
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Box>
          <Grid container mt={2} />
        </Box>
      </TabPanel>
    </>
  );
}
