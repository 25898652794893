/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Popover,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import { CustomPicker, InjectedColorProps } from 'react-color';
import {
  Alpha,
  Hue,
  Saturation,
  Checkboard,
} from 'react-color/lib/components/common';

export type RWColorPickerOptions = {
  showFillColor?: boolean;
  showSaturation?: boolean;
  showHue?: boolean;
  hueAlignment?: 'vertical' | 'horizontal';
  showAlpha?: boolean;
  fillClickable?: boolean;
  fillLabel?: string;
};

export type RWColorPickerProps = {
  // eslint-disable-next-line react/require-default-props
  options?: RWColorPickerOptions;
} & InjectedColorProps;

const defaultOptions: RWColorPickerOptions = {
  showFillColor: true,
  showSaturation: true,
  showHue: true,
  hueAlignment: 'vertical',
  showAlpha: true,
  fillClickable: false,
  fillLabel: 'Fill/Color',
};

function RWColorPicker(props: RWColorPickerProps) {
  const { hsl, rgb, hex, onChange, options: originalOptions } = props;

  const options = { ...defaultOptions, ...originalOptions };

  const handleColorChange = (color: any) => {
    if (onchange !== undefined) {
      onChange?.(color);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (options?.fillClickable) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const customPointer = (
    <Box
      sx={{
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderBottom: '5px solid transparent',
        borderLeft: '10px solid black',
        borderRight: '10px solid black',
      }}
    />
  );

  return (
    <Box>
      <Grid container justifyContent="center" alignItems="center">
        {options?.showSaturation && (
          <Box position="relative" left={0} width={144} height={126}>
            <Saturation {...props} color={rgb} onChange={handleColorChange} />
          </Box>
        )}
        {options?.showHue && (
          <Box
            position="relative"
            sx={
              options.hueAlignment === 'horizontal'
                ? {
                    mt: 2,
                    left: 0,
                    width: 192,
                    height: 10,
                  }
                : {
                    ml: 3,
                    right: 0,
                    width: 23,
                    height: 126,
                  }
            }
          >
            <Hue
              {...props}
              color={hsl}
              onChange={handleColorChange}
              direction={options.hueAlignment}
            />
          </Box>
        )}
        {options?.showAlpha && (
          <Box position="relative" my={10} left={0} width={192} height={10}>
            <Alpha {...props} color={hsl} onChange={handleColorChange} />

            {/* <Slider
              defaultValue={0}
              onChange={handleColorChange}
              aria-label="Alpha"
              color="secondary"
            /> */}
          </Box>
        )}
      </Grid>
      {options?.showFillColor && (
        <Box>
          <FormControlLabel
            value={options.fillLabel}
            label={options.fillLabel}
            sx={{ mt: 0, ml: 0 }}
            control={
              <Box
                position="relative"
                width={48}
                height={30}
                ml={1}
                sx={{ cursor: 'pointer' }}
              >
                <Checkboard size={12} white="#fff" grey="#c8c8c8" />
                <Box
                  position="relative"
                  component="button"
                  border={0}
                  p={0}
                  width={48}
                  height={30}
                  sx={{ backgroundColor: hex, opacity: hsl?.a }}
                  onClick={handleClick}
                />
              </Box>
            }
            labelPlacement="start"
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              maxWidth={220}
              p={2}
            >
              <Grid item>
                <Box position="relative" left={0} width={144} height={126}>
                  <Saturation
                    {...props}
                    color={rgb}
                    onChange={handleColorChange}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  position="relative"
                  sx={{
                    mt: 2,
                    left: 0,
                    width: 192,
                    height: 10,
                  }}
                >
                  <Hue
                    {...props}
                    color={hsl}
                    onChange={handleColorChange}
                    direction="horizontal"
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  position="relative"
                  mt={2}
                  left={0}
                  width={192}
                  height={10}
                >
                  <Alpha {...props} color={hsl} onChange={handleColorChange} />
                </Box>
              </Grid>
            </Grid>
          </Popover>
        </Box>
      )}
    </Box>
  );
}

export default CustomPicker(RWColorPicker);
