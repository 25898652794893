/* eslint-disable react/require-default-props */
/* eslint-disable object-curly-newline */
import React from 'react';
import { Box } from '@mui/material';

export type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
  setValue?: (value: number) => void;
};

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#141218',
            borderRadius: '0px 0px 4px 4px',
            borderTop: '1px solid #36343b',
            minHeight: 500,
            // maxHeight: 500,
            p: 2,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
