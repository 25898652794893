/* eslint-disable react/react-in-jsx-scope */

import { ReactNode } from 'react';
import '../styles/customHover.scss';

interface Props {
  children: ReactNode;
  onClick: () => void;
}

function PanelItemNew({ children, onClick }: Props) {
  return (
    <button type="button" className="panelCard" onClick={onClick}>
      <div className="panelCard__item">{children}</div>
      <span className="background" />
    </button>
  );
}

export default PanelItemNew;
